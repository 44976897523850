.view-container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.body-container {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.content-container {
  flex-grow: 1;
}
