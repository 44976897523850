.container {
  border: none;
}

.logo {
  padding: 16px;
  align-items: flex-start;
  height: 30px;
  width: 130px;
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
